import css from 'styled-jsx/css';

export default css`.footer {
  color: white;
  background-color: #101820;
}

.join {
  font-weight: 300;
  max-width: 700px;
  text-align: center;
  margin: 5.5rem auto;
  font-size: 1.375rem;
}

.logos {
  border-top: 1px solid #263445;
  border-bottom: 1px solid #263445;
  padding: 3.25rem 0 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.join-link {
  position: relative;
  padding: 0.025rem 0.25rem;
  margin-left: -0.25rem;
  display: inline-block;
  z-index: 10;
  text-decoration: none;
  color: inherit;
}

.join-link::before {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 25%;
  background-color: #344352;
  z-index: -1;
  transition: background-color 400ms;
}

.join-link:hover::before {
  background-color: #4a6580;
}

.logo-link {
  width: 20%;
  text-align: center;
  min-width: 150px;
  margin-bottom: 2rem;
}

.logo {
  max-height: 70px;
  max-width: 106px;
}

.footer-menu {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.125em;

  padding-top: 2.25rem;
  padding-bottom: 1.25rem;
}

.copyright {
  margin-top: 2rem;
  order: 20;
}

@media (min-width: 768px) {
  .footer-menu {
    padding-top: 1.25rem;
    flex-direction: row;
  }

  .copyright {
    margin-top: 0;
    order: 0;
  }

  .left-footer-link {
    margin-right: auto;
  }

  .right-footer-link {
    margin-left: auto;
  }

  .join {
    margin: 7rem auto 4rem;
  }

  .logos {
    padding: 6rem 0 3rem;
  }

  .logo-link {
    margin-bottom: 3rem;
  }

  .logo {
    max-height: 100px;
    max-width: 126px;
  }
}

@media (min-width: 1024px) {
  .join {
    font-size: 1.625rem;
    margin: 8.5rem auto 6rem;
  }
}

@media (min-width: 1440px) {
  .join {
    font-size: 1.625rem;
    margin: 12rem auto 7rem;
  }

  .footer-menu {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

.linkedin {
  background-image: url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2036%2036%22%3E%3Cg%20data-name%3D%22Layer%202%22%3E%3Cpath%20d%3D%22M33.34%200H2.66A2.63%202.63%200%200%200%200%202.6v30.8A2.63%202.63%200%200%200%202.66%2036h30.68A2.63%202.63%200%200%200%2036%2033.4V2.6A2.63%202.63%200%200%200%2033.34%200zM10.68%2030.68H5.33V13.5h5.35zM8%2011.15a3.1%203.1%200%201%201%203.09-3.1A3.1%203.1%200%200%201%208%2011.15zm22.68%2019.53h-5.34v-8.36c0-2%200-4.55-2.78-4.55s-3.2%202.17-3.2%204.41v8.5H14V13.5h5.12v2.34h.07a5.6%205.6%200%200%201%205.05-2.77c5.41%200%206.41%203.56%206.41%208.18z%22%20fill%3D%22%23fff%22%20data-name%3D%22Layer%201%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  background-size: 1rem 1rem;
  background-repeat: no-repeat;
  background-position: center center;
  width: 1.125rem;
  height: 1.125rem;
  overflow: hidden;
  text-indent: -999em;
}

.footer-link,
.copyright {
  color: inherit;
  padding: 0.75rem;
}
`