import css from 'styled-jsx/css';

export default css`.studio-grid {
  display: flex;
  flex-wrap: wrap;
}

.studio {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0 2rem;
  line-height: 24px;
  font-weight: 300;
  width: 100%;
}

.studio-body {
  margin: 0 auto;
  max-width: 340px;
}

.city {
  font-size: 26px;
  margin: 1.25rem 0 0.5rem 0;
}

.title {
  margin-top: 50px;
}

@media (min-width: 768px) {
  .studio {
    width: 50%;
    flex-basis: 50%;
  }

  .title {
    margin-top: 106px;
    margin-bottom: 4.25rem;
  }
}

@media (min-width: 1024px) {
  .studio {
    width: 33.333%;
    flex-basis: 33.333%;
    flex-shrink: 0;
    margin-bottom: 50px;
  }
}

@media (min-width: 1920px) {
  .studio {
    width: 25%;
    flex-basis: 25%;
    flex-shrink: 0;
  }
}

@media (min-width: 1280px) {
  .title {
    margin-top: 106px;
    margin-bottom: 8.5rem;
  }
}
`