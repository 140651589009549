import css from 'styled-jsx/css';

export default css.global`@media (max-width: 767px) {
  .slick-slider {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 1.5rem;
  }

  .slick-slide {
    display: flex !important;
    height: 70vh;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .slick-slider {
    padding-top: 5vh;
    height: 90vh;
  }
}

.slick-slide div:focus {
  outline: none;
}

.slick-arrow {
  background: rgb(22, 31, 41, 0.91);
  border: none;
  color: white;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.1em;
  position: absolute;
  padding: 16px 50px;
  width: 220px;
  text-align: center;
  z-index: 100;
  top: 0;
  opacity: 0;
  pointer-events: none;
}

.slick-arrow:focus {
  pointer-events: auto;
  opacity: 1;
}

.slick-next {
  right: 0;
}

.slick-cloned button {
  display: none;
}

.close {
  display: block;
  opacity: 1;
  right: 0;
  padding: 1rem;
  width: auto;
  pointer-events: auto;
  cursor: pointer;
  font-size: 1rem;
  background-color: transparent;
}

@media (min-width: 768px) {
  .close {
    display: none;
  }
}
`