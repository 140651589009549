import css from 'styled-jsx/css';

export default css`.header {
  pointer-events: auto;
  overflow: hidden;
  flex-shrink: 0;
}

.video-intro {
  text-align: center;
  height: 100vh;
  will-change: opacity;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex;
}

.fixed .video-intro {
  pointer-events: none;
}

.video-wrap {
  flex-shrink: 1;
  height: 100%;
  position: relative;
  will-change: transform, opacity;
  opacity: 0;
  overflow: hidden;
  transform: translateY(-60px);
  transition: transform 900ms 800ms ease-out, opacity 900ms 800ms ease-out;
}

.video {
  width: 100%;
  height: auto;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  object-fit: contain;
  will-change: transform, opacity;
}

@media (min-width: 768px) {
  .video {
    object-fit: cover;
  }
}

:global(.is-mounted) .video-wrap {
  transform: translateY(0);
  opacity: 1;
}

.mount-done .video-wrap {
  transition: none;
}

.footer {
  display: flex;
  padding: 2rem 0 4rem;
  flex-shrink: 0;
}

@media (min-width: 768px) {
  .footer {
    padding-bottom: 2rem;
  }
}

@keyframes swoosh {
  0% {
    background-position: 100%;
  }

  70% {
    background-position: 0;
  }
}

.cto {
  color: white;
  padding: 1rem 0;
  font-weight: 300;
  letter-spacing: 0.125em;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  display: inline-block;
  margin: 0 auto;
  will-change: transform;
  transform: translateY(30px);
  opacity: 0;
  transition: transform 900ms 1200ms ease-out, opacity 900ms 1200ms ease-out;

  background-image: -webkit-linear-gradient(
    0deg,
    rgba(16, 24, 32, 0.86) 40%,
    rgba(255, 255, 255, 1) 50%,
    rgba(16, 24, 32, 0.86) 60%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 400% auto;
  animation-name: swoosh;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

:global(.is-mounted) .cto {
  transform: translateY(0);
  opacity: 1;
  transition: none;
}

.cto::before,
.cto::after {
  display: block;
  width: 30%;
  height: 100%;
  position: absolute;
  top: 0;
}

.cto::after {
  left: 0;
  background: linear-gradient(90deg);
}

.cto::before {
  right: 0;
  background: linear-gradient(
    270deg,
    rgba(16, 24, 32, 0.86),
    rgba(16, 24, 32, 0)
  );
}
`