import css from 'styled-jsx/css';

export default css`h3 {
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  margin-top: 4.5rem;
  margin-bottom: 0.875rem;
}

@media (min-width: 768px) {
  h3 {
    font-size: 26px;
    line-height: 32px;
    margin-top: 6rem;
    margin-bottom: 2rem;
  }
}

@media (min-width: 1280px) {
  h3 {
    margin-bottom: 2.75rem;
  }
}
`