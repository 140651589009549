import css from 'styled-jsx/css';

export default css`.gallery {
  padding: 0;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
  position: relative;
}

.clear {
  clear: both;
  display: block;
}

.img img,
.img picture {
  box-shadow: 0 0 16px 2px rgba(0, 0, 0, 0.16);
}

:global(.plx) {
  padding: 0;
  position: absolute;
  will-change: transform;
}

.image-container {
  margin: 0;
  width: 100%;
  height: 0;
  overflow: visible;
  top: 0;
  box-sizing: content-box
}

:global(.base) {
  pointer-events: none;
}

:global(.base) :global(.img),
:global(.base) picture {
  pointer-events: auto;
}

.hover :global(.img),
.hover picture {
  pointer-events: auto;
}

:global(.plx) :global(.pos) {
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
}

:global(.hover) {
  z-index: 650;
  cursor: pointer;
}

:global(.hover) :global(.pos) {
  top: 0;
  display: block;
  transition: opacity 1000ms;
  opacity: 0;
}

:global(.hover) :global(.pos):hover {
  opacity: 1;
}

:global(.hover) :global(.img) {
  transform: scale(1);
  transition: transform 200ms, opacity 1000ms;
  /* fixes blue ghost outline in firefox */
  outline: 1px solid transparent;
}

:global(.hover) .pos:hover :global(.img) {
  opacity: 1;
  transform: scale(1.05);
}

.legend {
  transform: scale(1.05);
  position: absolute;
  z-index: 20;
  color: white;
  text-transform: uppercase;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  background-color: #00000059;
  top: 0;
  font-size: 1.375rem;
  padding: 2rem;
}
`