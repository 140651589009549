import css from 'styled-jsx/css';

export default css`.about {
  max-width: 410px;
  column-gap: 2rem;
  color: white;
  font-weight: 300;
  font-size: 20px;
  letter-spacing: 0.045em;
  line-height: 1.375em;
  margin-bottom: 5.25rem;
}

.about> :global(p) {
  margin-top: 0;
}

@media (min-width: 500px) {
  .about {
    margin-bottom: 6rem;
  }
}

@media (min-width: 768px) {
  .about {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    column-count: 2;
    font-size: 22px;
    margin-bottom: 6.5rem;
  }
}

@media (min-width: 1280px) {
  .about {
    margin-bottom: 12rem;
  }
}

@media (min-width: 1920px) {
  .about {
    margin-bottom: 20rem;
  }
}
`