import css from 'styled-jsx/css';

export default css`.row {
  padding-bottom: 2rem;
}

.image {
  max-width: 100%;
}

.article {
  margin-bottom: 4rem;
  cursor: pointer;
}

.title {
  margin-bottom: 0.5rem;
}

.lead {
  margin-top: 0;
  margin-bottom: 1.25rem;
}

:global(.read-more)::before {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 50%;
  background-color: #e5e7e8;
  z-index: -1;
  transition: background-color 400ms;
}

.article:hover :global(.read-more)::before {
  background-color: #daeef8;
}

:global(.read-more) {
  position: relative;
  padding: 0.125rem 0.25rem;
  margin-left: -0.25rem;
  display: inline-block;
  z-index: 10;
  text-decoration: none;
  color: inherit;
}
}

@media (min-width: 1024px) {
  .row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 5rem;
  }

  .article {
    width: calc(50% - 2rem);
  }
}
`