import css from 'styled-jsx/css';

export default css`.article-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  background: rgba(22, 31, 41, 1);
}
`