import css from 'styled-jsx/css';

export default css`.nav-label {
  background: none;
  display: none;
  border: none;
  color: white;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 300;
  font-style: normal;
  pointer-events: none;
  letter-spacing: 0.125em;
  position: absolute;
  padding: 0;
  text-align: center;
  z-index: 100;
  top: 50%;
  opacity: 0;
  transition: opacity 500ms;
}

.nav-label:focus {
  outline: 0;
  background-color: #fff1;
}

@media (min-width: 768px) {
  .nav-label {
    width: 140px;
    display: block;
    height: 90vh;
    top: 5vh;
    pointer-events: all;
  }

  .nav-label:hover {
    opacity: 1;
  }
}

@media (min-width: 1280px) {
  .nav-label {
    width: 200px;
    display: block;
    height: 90vh;
    top: 5vh;
    pointer-events: all;
  }
}


.nav-label--prev {
  left: 0;
}

.nav-label--next {
  right: 0;
}

.nav-label--prev:hover {
  opacity: 1;
}

.nav-label--next:hover {
  opacity: 1;
}

.nav-label:disabled {
  display: none !important;
}
`