import css from 'styled-jsx/css';

export default css`.container {
  box-sizing: content-box;
  max-width: 320px;
  overflow: hidden;
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .container {
    max-width: 612px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 890px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1920px) {
  .container {
    max-width: 1455px;
  }
}
`