import css from 'styled-jsx/css';

export default css`.main-nav :global(.navbar) {
  will-change: transform;
  transform: translateY(-30px);
  transition: transform 900ms 0ms ease-out;
}

:global(.is-mounted) .main-nav :global(.navbar) {
  transform: translateY(0);
}

:global(.navbar) {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

:global(.navbar .logo) {
  vertical-align: top;
  max-width: 132px;
}

.h1 {
  margin: 0;
}

:global(.masthead-a) {
  font-weight: 300;
  color: white;
  padding: 0.75rem;
  text-decoration: none;
}

:global(.masthead-a.active) {
  text-decoration: underline;
}

@media (min-width: 768px) {
  :global(.navbar) {
    padding-top: 3.25rem;
    padding-bottom: 2rem;
  }

  :global(.navbar) .logo {
    max-width: 192px;
  }
}
`