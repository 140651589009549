import css from 'styled-jsx/css';

export default css`.carouselImage {
  max-width: 100%;
  cursor: pointer;
  margin: 0 auto;
  display: block;
}

.carouselSlide {
  position: relative;
  max-width: 100vw;
  padding: 0 20px;
}

@media (min-width: 1024px) {
  :global(.carouselImage) {
    width: 100%;
    height: auto;
    max-height: 85vh;
    margin: 0 0px;
    flex-shrink: 1;
  }

  .carouselSlide {
    padding: 0 140px;
    height: 90vh;
    display: inline-flex !important;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
    width: 100vw !important;
  }
}

.closeBtn {
  position: absolute;
  top: 0;
  height: 100%;
  width: calc(100% - 40px);
  background: rgba(0, 0, 0, 0);
  text-shadow: 0 0 15px rgba(0, 0, 0, 0);
  border: none;
  cursor: pointer;
  color: white;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 0.1em;
  position: absolute;
  text-align: center;
  z-index: 100;
  bottom: 0;
  opacity: 0;
  pointer-events: none;
  transition: opacity 400ms;
  will-change: opacity;
  pointer-events: none;
  display: none;
}

.closeBtn:focus {
  outline: 0;
  box-shadow: 0 0 0px 4px #fff2;
}

.carouselSlide img {
  transition: opacity 400ms;
  will-change: opacity;
}

.closeBtn:focus,
:global(.slick-current).carouselSlide .closeBtn:hover {
  opacity: 1;
}

.figure {
  cursor: pointer;
  margin: 0;
  height: auto;
  max-height: 90vh;
  max-width: 100%;
  position: relative;
}

.caption {
  color: white;
  margin-top: 0.9375rem;
}

.caption:empty {
  display: none;
}

@media (min-width: 768px) {
  .carouselSlide:hover img {
    opacity: 0.8;
  }

  :global(.slick-current) .closeBtn {
    pointer-events: auto;
    display: block;
  }

  .closeBtn {
    width: 100%;
  }

  .figure {
    max-width: calc(100% - 40px);
  }
}

@media (min-width: 1280px) {
  .carouselSlide {
    padding: 0 200px;
  }
}
`